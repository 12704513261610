// gatsby-browser.js
import React from 'react';
import Layout from './src/components/Layout';
import { getCookie } from './src/components/utils';

export const onClientEntry = () => {
  window.allConsentGranted = function () {
    // console.log('allConsentGranted function called');
    // console.log(window.gtag); // For debugging purposes
    if (window.gtag) {
      window.gtag('consent', 'update', {
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        ad_storage: 'granted',
        analytics_storage: 'granted',
      });
      // console.log('Consent status updated via gtag');
    }
  };

  // Check if the user has accepted the cookies
  const consentCookie = getCookie('gatsby-gdpr-google-analytics');
  // console.log(`gatsby-gdpr-google-analytics cookie value: ${consentCookie}`);
  if (consentCookie === 'true') {
    window.allConsentGranted();
  }
};

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}
