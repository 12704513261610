import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

export default function SEO({ children, location, description, title, image }) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          siteUrl
          description
          keywords
          image
        }
      }
    }
  `);

  return (
    <Helmet titleTemplate={`${site.siteMetadata.title}`}>
      <html lang='lv' />
      <title>{title}</title>
      {/* ICONS */}
      <link rel='icon' href='/favicon.svg' type='image/svg+xml' />
      <link rel='alternate icon' href='/favicon.ico' />
      {/* Meta tags*/}
      <meta name='viewport' content='width=device-width, initial-scale=1.0' />
      <meta charSet='utf-8' />
      <meta name='description' content={site.siteMetadata.description} />
      {/*Open Hraph*/}
      {location && <meta property='og:url' content={location.href} />}
      <meta property='og:image' content={image || '/favicon.ico'} />
      <meta property='og:title' content={title} key='ogtitle' />
      <meta
        property='og:site_name'
        content={site.siteMetadata.title}
        key='ogsitename'
      />
      <meta
        property='og:description'
        content={site.siteMetadata.description}
        key='ogdescription'
      />
      <meta name='keywords' content={site.siteMetadata.keywords} />
      {children}
    </Helmet>
  );
}
