// src/components/Layout.js

import React from 'react';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import SEO from './SEO';
import { CookieNotice } from 'gatsby-cookie-notice';
import './cookiesBanner.css';

export default function Layout({ children }) {
  return (
    <>
      <CookieNotice
        backgroundClasses={'main'}
        backgroundWrapperClasses={'fixed'}
        personalizeButtonText={'Izvēlēties sīkdatnes'}
        acceptButtonText={'Piekrītu'}
        declineButtonText={'Nepiekrītu'}
        declineButtonClass={'btn-secondary'}
        personalizeButtonClasses={'buttons'}
        wrapperClasses={'wrapperClass'}
        acceptButtonClasses={'buttons'}
        cookieMapClasses={'cookiesListText'}
        personalizeValidationText={'Saglabāt'}
        personalizeValidationClasses={'btn-secondary'}
        cookies={[
          {
            name: 'gatsby-gdpr-google-analytics',
            editable: true,
            default: true,
            title: 'Google Analytics',
            text: 'Google Analytics ir Google statistikas rīks, kas ļauj izmērīt vietnes auditoriju.',
          },
        ]}
      >
        <h4>Mēs savā vietnē izmantojam sīkdatnes</h4>
        <p className='cookiesText'>
          Piekrītot sīkdatņu izmantošanai, tiks nodrošināta tīmekļa vietnes
          optimāla darbība. Turpinot vietnes apskati, jūs piekrītat, ka
          izmantosim sīkdatnes jūsu ierīcē. Savu piekrišanu jūs jebkurā laikā
          varat atsaukt, nodzēšot saglabātās sīkdatnes
        </p>
      </CookieNotice>
      <SEO title={`Zeltābeles pasaka`} />
      <GlobalStyles />
      <Typography />
      {children}
    </>
  );
}
